import Vue from 'vue'

export function tagPlan ( state, value ) {
  state.tagPlan = value
}
export function tagKey ( state, value ) {
  state.tagKey = value
}
export function tagUser ( state, value ) {
  state.tagUser = value
}

export function addPlan ( state, payload ) {
  Vue.set(state.byPlan, payload.TS, payload)
  // state.byPlan[payload.TS] = payload
}
export function clearRecordings ( state ) {
  state.byTimestamp = {}
  state.byPlan = {}
  state.byKey = {}
}
export function rejectCurrentRecording ( state ) {
  if (state.tagPlan in state.byPlan) {
    delete state.byPlan[state.tagPlan]
  }
  state.tagPlan = false
}

export function recorded ( state, recording ) {
  state.byTimestamp[recording.TS] = recording

  state.byPlan[state.tagPlan].recordings.push(recording.TS) // but this is in q order, not use order

  if (state.tagKey) {
    state.byPlan[state.tagPlan].keys[state.tagKey] = recording
    state.byKey[state.tagKey] = state.byKey[state.tagKey] || []
    state.byKey[state.tagKey].push(recording.TS)
  }
}
