export function publicDocuments (state) {
  return state.publicDocuments
}

export function themes (state) {
  return state.themes
}

export function theme (state) {
  return state.theme
}

export function script (state) {
  return state.script
}
export function scriptSteps (state) {
  return state.scriptSteps
}

export function questions (state) {
  return state.questions
}

export function yourURLFormat (state) {
  return state.yourURLFormat
}
export function fieldList (state) {
  return state.fieldList
}
export function fields (state) {
  return state.fields
}


