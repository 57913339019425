export function allRecordingsByTimestamp ( state ) {
  return state.byTimestamp
}
export function byPlan ( state ) {
  return state.byPlan
}

export function practiceRecordings ( state ) {
  // replaces practices.URL
  /*
  URL: Array(1)
0:
label: "Dec 22 @ 12:14 "
TS: 1577042040272
menuItem: t
handler: ƒ ()
treeNode: false
   */
  return Object.values(state.byPlan)
}

export function practiceRecordingsExist ( state ) {
  return state.byPlan !== {}
}
