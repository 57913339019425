/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/


import Vue from 'vue'

import {Quasar,QImg,QSelect,QCardActions,QTabs,QTab,QTabPanels,QTabPanel,QSpinnerDots,QChatMessage,QBtnGroup,QAvatar,QTree,QSplitter,QOptionGroup,QField,QInput,QSeparator,QFooter,QBadge,QSlider,QExpansionItem,QCard,QCardSection,QRadio,QCheckbox,QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QScrollArea,TouchRepeat,Ripple,AppFullscreen,Notify,Dialog,LocalStorage,SessionStorage} from 'quasar'


Vue.use(Quasar, { config: {},components: {QImg,QSelect,QCardActions,QTabs,QTab,QTabPanels,QTabPanel,QSpinnerDots,QChatMessage,QBtnGroup,QAvatar,QTree,QSplitter,QOptionGroup,QField,QInput,QSeparator,QFooter,QBadge,QSlider,QExpansionItem,QCard,QCardSection,QRadio,QCheckbox,QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QScrollArea},directives: {TouchRepeat,Ripple},plugins: {AppFullscreen,Notify,Dialog,LocalStorage,SessionStorage} })
