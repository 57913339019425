export function script (state, value) {
  state.script = value
}
export function scriptSteps (state, value) {
  state.scriptSteps = value
}

export function fieldList (state, value) {
  state.fieldList = value
}

export function fields (state, value) {
  state.fields = value
}

export function yourURLFormat (state, value) {
  state.yourURLFormat = value
}

export function questions (state, value) {
  state.questions = value
}

export function theme (state, value) {
  state.theme = value
}
