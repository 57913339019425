const nicer = (wild) => {
  if (wild) {
    return encodeURIComponent(wild.replace(/_/g, '%5f').replace(/ /g, '_'))
  } else {
    return ''
  }
}

export function script ({ commit, dispatch }, value) {
  commit('script', value)
  let parts = value.split('|')
  let steps = []
  for (let ctr = 0; ctr < parts.length; ctr += 2) {
    let text = parts[ctr]
    let key = (parts[ctr + 1] || '').trim()

    if (text.trim().length) {
      steps.push({
        text,
        key,
      })
    }
  }

  commit('scriptSteps', steps)
  dispatch('url')
}

export function questions ({ commit, dispatch, state }, value) {
  if (typeof value === 'string') {
    let vals
    let fieldsArray = value.split(state.recordDelim)
    let answerMap = {}
    let fields = []

    fieldsArray.forEach(fld => {
      while ((vals = state.regex.exec(fld)) !== null) {
        let [whole, question, hint, answer] = vals

        question = decodeURIComponent(question)
        hint = decodeURIComponent(hint)
        answer = decodeURIComponent(answer)

        if (question && question !== 'grill') {
          let newField
          if (question.toLowerCase() === 'title') {
            // this.urlTitle = answer
          } else {
            if (!answer) {
              answer = question
              question = 'Item ' + (fields.length + 1)
              newField = {
                whole,
                question,
                hint,
                answer,
                recordings: {},
                scripts: [],
                ts: new Date().getTime(),
                index: fields.length + 1,
              }

            } else {
              newField = {
                whole,
                question,
                hint,
                answer,
                recordings: {},
                scripts: [],
                ts: new Date().getTime(),
                index: fields.length + 1,
              }
            }

            fields.push(newField)
            answerMap[answer] = newField
          }
        }
      }
    })

    commit('fields', fields)

    if (Object.keys(answerMap).length) {
      state.scriptSteps.forEach(step => {
        let match = answerMap[step.key]
        if (match) {
          step.field = match
          match.scripts.push(step)
        }
      })
    }

  }
  else {
    commit('questions', value)
  }
  dispatch('url')
}

export function theme ({ commit, dispatch, state }, value) {
  // initially set by slug name.  If sent an object, it may be customized
  console.log('theme set: ', value)
  if (typeof value === 'string') {
    // do we have a change?
    if (state.theme.slug === value && state.theme.origin === 'set by name') {
      console.log('same theme set')
      return
    }

    let found = state.themes.find( the => the.slug === value )
    if (found) {
      found.origin = 'set by name'
    }
    commit('theme', found || { slug: 'diary' })
  }
  else {
    value = value || { slug: 'diary', origin: 'set as object' }
    commit('theme', value)
  }
  dispatch('url')
}
export function url ({ commit, state }) {
  let fieldList = []
  let matches = state.script.match(/\|/g)
  if (matches) {
    let pipes = matches.length
    let all = state.script.split('|')

    let count = all.length
    if (pipes % 2 === 0) {
      for (let ctr = 1; ctr < count; ctr += 2) {
        fieldList.unshift(all[ctr])
      }
    }
  }
  commit('fieldList', fieldList)

  let yourURLFormat = ''
  let fixed = []
  fieldList.forEach(fld => {
    let q = nicer(state.questions[fld] || 'What is a word like ' + fld + '?')
    let a = nicer(fld)
    let h = false

    if (q) {
      if (h) {
        fixed.push(q + '(' + h + ')' + ':' + a)
      } else {
        if (a) {
          fixed.push(q + ':' + a)
        } else {
          fixed.push(q)
        }
      }
    }
  })

  let querystring = '?theme=' + state.theme.slug
  querystring += '&script=' + nicer(state.script)
  yourURLFormat = '/grill/' + fixed.join('/') + querystring
  commit('yourURLFormat', yourURLFormat)
}

export function syncURL ({ commit, dispatch, state }, route) {
  let document
  if (route.name === 'createGrillThemed' || route.name === 'createGrill') {
    // script either initial or as last set, so no change necessary
    // convert existing questions
    let qs = state.questions









    if (state.fields.length) {
      state.fields.forEach( fld => {
        qs[fld.answer] = fld.question
      })
    }
    dispatch('questions', qs)

    // ensure theme matches
    dispatch('theme', route.params.slug || route.query.theme || 'diary')
    return
  }
  if (route.name === 'scene') {
    document = state.publicDocuments[state.featuredDocument]
    document.theme = route.params.slug
  }
  else if (route.query.script) {
    document = {}
    document.theme = route.query.theme
    document.questions = route.path.replace(/_/g, ' ')
    document.person = [route.query.script.replace(/_/g, ' ')]
  } else {
    document = state.publicDocuments[state.featuredDocument]
  }



  dispatch('script', document.person[0])
  dispatch('questions', document.questions)
  dispatch('theme', document.theme || { slug: 'diary' })


  // commit('theme', value || { slug: 'diary' })
  // dispatch('url')
}
