export function startPlan ({ commit, dispatch, state }, payload) {
  commit('tagPlan', payload.TS)
  commit('addPlan', payload)
}
export function recorded ({ commit, dispatch, state }, payload) {
  /*
  {"ts":1577036274240,
  "blobUrl":"blob:https://localhost:8080/2c45c715-1e7d-4c70-9c92-e3af279d03d2",
  "mimeType":"audio/webm;codecs=opus",
  "size":7747,
  "blob":"[object Blob]",
  "TS":1577036274240,
  "lessonTS":1577036260453,
  "fromPlanStep":0}
   */

  commit('recorded', payload)
}
