export const config = {
  'apiKey': 'AIzaSyDchTgtzrHE9jjyfOz2YbPMaq3HZrdvnQc',
  'authDomain': 'confessions-lol.firebaseapp.com',
  'databaseURL': 'https://confessions-lol.firebaseio.com',
  'projectId': 'confessions-lol',
  'storageBucket': 'confessions-lol.appspot.com',
  'messagingSenderId': '1008179394510',
  'appId': '1:1008179394510:web:37799f237360389e4a1d9f',
  'measurementId': 'G-4MSQYELMS7'
}

/*

https://console.firebase.google.com/u/0/project/confessions-lol/database/firestore/data~2F

 */
