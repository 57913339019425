import Vue from 'vue'
import VueRouter from 'vue-router'
import gtm from 'src/components/gtm'

// import routes from './routes'
import routes from './grillRoutes' // grillSettings

Vue.use(VueRouter)

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}



/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function (/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.afterEach((to, from) => {
    gtm.logPage(to.path)
  })

  Router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      next({ name: to.name, params: to.params, query: from.query })
    } else {
      next()
    }
  })
  return Router
}
