const routes = [

  {
    path: '/',
    component: () => import('layouts/MyLayout.vue'),
    children: [
      {
        path: '/grill/create/:slug',
        name: 'createGrillThemed',
        component: () => import('pages/ComputerCreate.vue'),
        children: [],
      },
      {
        path: '/grill/create',
        name: 'createGrill',
        component: () => import('pages/ComputerCreate.vue'),
        children: [],
      },
      {
        path: '/grill/*',
        name: 'grill',
        component: () => import('pages/Grill.vue'),
        children: [],
      },
      {
        path: '/at/:slug',
        name: 'scene',
        comment: 'from list, clicked a scenario, give script of the day',
        component: () => import('pages/Grill.vue'),
        children: [],
      },
      {
        path: '',
        name: 'index',
        component: () => import('pages/GrillList.vue'),
        children: [],
      },
    ],
  },
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue'),
  })
}

export default routes
