import firebase from 'firebase/app'
import 'firebase/firestore'

import { config } from './confessions.js'
// const config = require('./confessions.js')


// Get a Firestore instance
export const db = firebase
  .initializeApp(config)
  .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }

// "async" is optional
export default async ({ Vue }) => {
  Vue.prototype.$db = db
}
/*

https://github.com/firebase/firebaseui-web

 */

//
// debugger
// db.collection('users').add({
//   first: 'Ada',
//   last: 'Lovelace',
//   born: 1815
// })
//   .then(function (docRef) {
//     debugger
//     console.log('Document written with ID: ', docRef.id)
//
//
//   })
//   .catch(function (error) {
//     console.error('Error adding document: ', error)
//   })
//
//
// db.collection('users').get().then((querySnapshot) => {
//   debugger
//   querySnapshot.forEach((doc) => {
//     console.log(`${doc.id} => ${doc.data()}`)
//   })
// })
