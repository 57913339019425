export default {
  script: 'I wish I could |fly|',
  scriptSteps: [],
  theme: {
    slug: ''
  },
  fieldList: ['fly'],
  fields: [],
  questions: {},
  yourURLFormat: '/grill/What_is_a_word_like_fly%3F:fly?theme=barber&&script=I_wish_I_could_%7Cfly%7C',
  featuredDocument: 0,
  publicDocuments: [
    {
      category: 'Nice problems to have',
      title: 'At least I get played with',

      theme: 'diary',
      questions: 'What is your favorite food?(Mac and Cheese):food/What is a smell you can\'t stand?(wet shoes):smell/What is your pet\'s name?(Mugsly):pet name',
      /* questions2: 'What is your favorite food?(Mac and Cheese):food/What is a smell you can\'t stand?(wet shoes):smell/What is your pet\'s name?(Mugsly):pet name/What is your name anyway?(that guy):name', */
      person: [
        'Sometimes I think |pet name| only loves me because I taste like |food| and smell like |smell|',
        'Face it: |pet name| only loves you |name| because you taste like |food| and smell like |smell|',
        '|pet name| only loves |name| because they taste like |food| and smell like |smell|',
        ]
    }
  ],
  themes: [
     {
      slug: 'barber',
      title: 'barber visit',
      imageCaption: 'At your barber',
      chatName: 'Your barber',
      intro: 'You seem tense.  What\'s on your mind dude?',
      introRepeat: 'You just had a cut.  Is something else on your mind?',
      SayFirst: 'Hi, Good to see you.  Help me remember, ',
      credit: 'Photo by Stefan Lorentz from Pexels',
      endAllButton: 'STOP! Ear gets nicked, run from shop',
      playAllButton: 'A month later, you overhear:',
      expungeButton: 'Comb-over the experience',
      answerButton: 'Record Answer',
       sort: 40,

    },
    {
      slug: 'job_interview',
      title: 'job interview',
      imageCaption: 'Sitting for a job interview',
      chatName: 'The Mob',
      intro: 'Tell us a little about yourself...',
      introRepeat: 'Put on a disguise and try again...',
      SayFirst: 'There were some gaps on your resume we want to talk about.  First, ',
      credit: 'Photo by Tim Gouw from Pexels',
      endAllButton: 'STOP! Nervously vomit, excuse yourself',
      playAllButton: 'What does your reference say about you? ',
      expungeButton: 'Bribe everyone to never mention this',
      answerButton: 'Record Answer',
      sort: 20,
    },
    {
      slug: 'diary',
      title: 'diary',
      imageCaption: 'Writing in your secret diary about today...',
      chatName: 'Daria the Diary',
      intro: 'Dear Diary, Don\'t tell anyone but ...',
      introRepeat: 'Oh, and another thing...',
      SayFirst: 'I have been waiting for you all day! ',
      credit: 'Photo by Negative Space from Pexels',
      endAllButton: 'STOP! go Farenheit 451 and burn this book!',
      playAllButton: 'A visitor to your house reads your diary',
      expungeButton: 'Pop a breath mint and get over this.',
      answerButton: 'Record Answer',
      sort: 20,
    },
    {
      slug: 'gameshow',
      title: 'gameshow',
      imageCaption: 'Gameshow: Can you guess the most popular phrase',
      chatName: 'Host',
      intro: 'Fill in the blank: ',
      introRepeat: 'Championship round?',
      SayFirst: 'ok,  Guess what most people said.  Fill in the blank. ',
      credit: 'Photo by John-Mark Smith from Pexels',
      endAllButton: 'Choke up, you are on NATIONAL TV',
      playAllButton: 'Reruns',
      expungeButton: 'Retire your career',
      answerButton: 'Final Answer',
      sort: 10,
    },
    {
      slug: 'interrogation',
      title: 'interrogation',
      imageCaption: 'The Police Station',
      chatName: 'The Police Station',
      intro: 'We just hava a few questions.',
      introRepeat: 'Technically, you are free to leave.  But, ..',
      SayFirst: 'Just the facts, please',
      credit: 'Photo by Martin Lopez from Pexels',
      endAllButton: 'Your lawyer shows up',
      playAllButton: 'The jury will hear...',
      expungeButton: 'Expensive lawyer says...',
      answerButton: 'Record Answer',
      sort: 20,

    },
    {
      slug: 'inquisition',
      title: 'inquisition',
      imageCaption: 'No one expects the inquisition',
      chatName: 'The inquisition',
      intro: 'This will hurt us more than it hurts you.',
      introRepeat: 'Just confess',
      SayFirst: 'Someone we tortured gave us your name.  Now, ',
      credit: 'Photo by Stefan Lorentz from Pexels',
      endAllButton: 'You pass out',
      playAllButton: 'You will be executed, your confession ',
      expungeButton: 'Papal dispensation comes through',
      answerButton: 'Swear on the bible',
      sort: 90,

    },
    {
      slug: 'media',
      title: 'media',
      imageCaption: 'At your front door',
      chatName: 'Your media',
      intro: 'Do you have any words for our viewers?',
      introRepeat: 'Take 2?',
      SayFirst: 'Do you have any words for our viewers?',
      credit: 'Photo by Terje Sollie from Pexels',
      endAllButton: 'Someone else is more interesting',
      playAllButton: 'On TV tonight:',
      expungeButton: 'Sue and they shut down',
      answerButton: 'ON THE RECORD',
      sort: 80,

    }
  ],
  recordDelim: '/',
  fieldDelim: ':',
  hints: '()',
  regex: /^([^():]+)\(*([^:)]*)\)*:*(.*)$/gm,
}





