<template>
  <div id="q-app">
    <router-view/>

    <v-tour
      :callbacks="callbacksNoActions"
      :steps="RecordAndPractice"
      name="RecordAndPractice"
      ref="RecordAndPractice"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :key="tour.currentStep"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :previous-step="tour.previousStep"
            :step="tour.steps[tour.currentStep]"
            :stop="tour.stop"
            class="v-step"
            v-if="tour.steps[tour.currentStep]"
          >
            <template>
              <!--v-if="tour.currentStep === 0"-->
              <div slot="actions">
                <button @click.prevent="tour.stop" class="v-step__button">Stop Tour</button>
                <!--
                                <button @click="tour.previousStep" class="btn btn-primary">Previous Jim step</button>
                                <button @click="tour.nextStep" class="btn btn-primary">Next Jim step</button>
                -->
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>


    <v-tour
      :callbacks="callbacksNoActions"
      :steps="LoadALesson"
      name="LoadALesson"
      ref="LoadALesson"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :key="tour.currentStep"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :previous-step="tour.previousStep"
            :step="tour.steps[tour.currentStep]"
            :stop="tour.stop"
            class="v-step"
            v-if="tour.steps[tour.currentStep]"
          >
            <template v-if="tour.currentStep === 0">
              <div slot="actions">
                <button @click.prevent="tour.stop" class="v-step__button">Abandon Tour</button>
                <!--
                                <button @click="tour.previousStep" class="btn btn-primary">Previous Jim step</button>
                                <button @click="tour.nextStep" class="btn btn-primary">Next Jim step</button>
                -->
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
  export default {
    name: 'App',
    methods: {
      // TODO: I18N translate help:  https://github.com/pulsardev/vue-tour/issues/1
      NoActionStart () {},
      NoActionNext (currentStep) {},
      NoActionPrevious (currentStep) {},
      NoActionStop (currentStep) {},
    },
    data () {
      return {
        tourOptions: {
          useKeyboardNavigation: false,
        },
        RecordAndPractice: [
          {
            target: '#toLesson',
            content: 'Click Button [Record Or Open Lesson]',
            params: {
              placement: 'bottom',
              enableScrolling: false,
              offset: 1000,
            },
          },
          {
            target: '#recordLessonButton',
            content: 'Click [RECORD LESSON] to start recording',
            params: {
              placement: 'bottom',
              enableScrolling: false,
            },
          },
          {
            target: '#recordLessonStopButton',
            content: 'Click [Stop] Recording',
            params: {
              placement: 'bottom',
              enableScrolling: false,
            },
          },
          {
            target: '#entireRegionTourArea',
            content: 'Select a region in the graph or [USE ENTIRE RECORDING]',
            params: {
              placement: 'bottom',
              enableScrolling: false,
            },
          },
          {
            target: '#RegionPracticeButton',
            content: 'The [Practice] button will record you, then play back the original and your practice',
            params: {
              placement: 'bottom',
              enableScrolling: false,
            },
          },
        ],
        callbacksNoActions: {
          // We expect user to click something, and that contains the actual command
          onStart: this.NoActionStart,
          onStop: this.NoActionStop,
          onNextStep: this.NoActionNext,
          onPreviousStep: this.NoActionPrevious,
        },

        LoadALesson: [
          {
            target: '#toLesson',
            content: 'Click "Record Or Open Lesson"',
          },
          {
            target: '#recordLesson',
            content: 'Start Recording',
          },
          {
            target: '#recordLessonStop',
            content: 'Stop Recording',
          },
        ],
      }
    },
  }
</script>

<style>
  .v-step {
    background: #50596c; /* #ffc107, #35495e */
    color: white;
    max-width: 320px;
    border-radius: 3px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    padding: 1rem;
    text-align: center;
  }

  .v-step__button {
    background: transparent;
    border: .05rem solid white;
    border-radius: .1rem;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: .8rem;
    height: 1.8rem;
    line-height: 1rem;
    outline: none;
    margin: 0 0.2rem;
    padding: .35rem .4rem;
    text-align: center;
    text-decoration: none;
    transition: all .2s ease;
    vertical-align: middle;
    white-space: nowrap;

  &
  :hover {
    background-color: rgba(white, 0.95);
    color: #50596c;
  }

  }
</style>
