import { vuexfireMutations } from 'vuexfire'
import Vue from 'vue'
import Vuex from 'vuex'

import recordings from './recordings'
import scripts from './scripts'
// import {LocalStorage} from 'quasar'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

let hostname = window.location.hostname.toLowerCase()
let title = 'Confessions'
let subtitle = 'Your words<br>used against you'

switch (hostname) {
  case 'confessions.lol':
  default: {
    title = 'Confessions'
    subtitle = 'Your words will be<br>used against you'
    break
  }

  case 'drill.school': {
    title = 'Drill.School'
    subtitle = 'Drill till you\'re chill'
  }
}

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    mutations: {
      ...vuexfireMutations,
    },
    modules: {
      recordings,
      scripts
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: false, // process.env.DEV
    state: { title, subtitle },
  })

/*
  Store.subscribe((mutation, state) => {
    // problem using quasar localstorage stuff
    localStorage.set('recordingsjs', JSON.stringify(state.recordingsState))

    // ls.set('recordingsjs', state.recordingsState)
  })
*/

  return Store
}
